























































































































































import { constants } from '@/utils/constants';
import { toasts } from '@/utils/toasts';
import Vue from 'vue';
import Component from 'vue-class-component';

import {
  createUser,
} from './user-management-api';

@Component
export default class extends Vue {
  email: string = '';
  username: string = '';
  password: string = '';
  confirmPassword: string = '';
  fullName: string = '';
  userType: string = constants.userAccountTypes.user;
  users: Array<any> = [];

  tipOptions: Array<any> = [
    { text: 'Utilizator', value: constants.userAccountTypes.user },
    { text: 'Administrator', value: constants.userAccountTypes.admin },
  ];

  async onAddUserOk(event) {
    event.preventDefault();
    const item = {
      emailAddress: this.email,
      password: this.password.trim(),
      username: this.username,
      fullName: this.fullName,
      userType: this.userType,
    };
    if (!this.validateFields(item)) {
      return;
    }

    try {
      await createUser(item);
      (<any>(this.$refs.modal)).hide();
      this.$emit('onSubmitAddUserEvent');
    } catch (err) {
      toasts.error('Eroare la adăugarea utilizatorului');
    }
  }

  resetFields() {
    this.email = '';
    this.username = '';
    this.password = '';
    this.confirmPassword = '';
    this.fullName = '';
    this.userType = constants.userAccountTypes.user;
  }

  validateFields(item) {
    const errors: Array<string> = [];

    if (!item.emailAddress) {
      errors.push('Utilizatorul are nevoie de o adresa de email!');
    }
    if (!item.password || item.password.length < 1) {
      errors.push('Utilizatorul are nevoie de o parolă validă!');
    }
    if (!this.confirmPassword || this.confirmPassword.length < 1) {
      errors.push('Utilizatorul are nevoie de o confirmare a parolei!');
    }
    if (item.password !== this.confirmPassword) {
      errors.push('Parolele nu se potrivesc!');
    }
    if (!item.username) {
      errors.push('Utilizatorul are nevoie de un nume de utilizator!');
    }
    if (!item.fullName) {
      errors.push('Utilizatorul are nevoie de un nume complet!');
    }
    if (!item.userType) {
      errors.push('Utilizatorul are nevoie de un tip!');
    }
    if (this.isUsernameDuplicate()) {
      errors.push('Numele de utilizator există deja!');
    }
    if (this.isEmailDuplicate()) {
      errors.push('Adresa de email există deja!');
    }
    if (errors.length) {
      this.showErrorsToast(errors, 'Eroare la validare');
      return false;
    }
    return true;
  }

  showErrorsToast(errors, toastId: string|undefined) {
    const h = this.$createElement;
    const vNodesMsg = [
      h('p', {}, 'Trebuie corectate următorele erori:'),
      h(
        'ul',
        {},
        errors.map((e) => h('li', {}, e)),
      )];
    toasts.error(vNodesMsg, {
      title: 'Eroare de validare',
      id: toastId,
    });
  }

  isUsernameDuplicate() {
    return this.users.some((user) => user.username === this.username);
  }
  isEmailDuplicate() {
    return this.users.some((user) => user.emailAddress === this.email);
  }

  showModal(users) {
    this.users = users;
    this.resetFields();
    (<any>(this.$refs.modal)).show();
  }
}

