




























































import Vue from 'vue';
import Component from 'vue-class-component';
import camelcaseKeys from 'camelcase-keys';
import { constants } from '@/utils/constants';

import { getUsers } from './user-management-api';

import AddUserModal from './AddUserModal.vue';

@Component({
  components: {
    AddUserModal,
  },
})
export default class extends Vue {
  items: Array<any> = [];
  tableBusy = false;
  fields = [
    {
      label: 'Nume utilizator',
      key: 'username',
    },
    {
      label: 'Adresă email',
      key: 'emailAddress',
    },
    {
      label: 'Nume complet',
      key: 'fullName',
    },
    {
      label: 'Tip utilizator',
      key: 'tip',
    },
    {
      label: 'Este activ',
      key: 'isActive',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  tipName(value) {
    if (value === constants.userAccountTypes.admin) return 'Administrator';
    if (value === constants.userAccountTypes.user) return 'Utilizator';
    return '-';
  }

  async loadGridPage() {
    try {
      this.tableBusy = true;
      this.items = camelcaseKeys(await getUsers());
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    } finally {
      this.tableBusy = false;
    }
  }

  async initialize() {
    await this.loadGridPage();
  }

  created() {
    if (!this.$store.getters.canAdminister) {
      this.$router.replace({ name: 'Dashboard' });
      return;
    }
    this.initialize();
  }

  onAddUser() {
    (<any>(this.$refs.addUserModal)).showModal(this.items);
  }

  async onSubmitAddUserEvent() {
    await this.loadGridPage();
  }
}
